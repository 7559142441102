<template>
  <div class="tech-support">
    <div class="tech-support__back">
      <img @click="$router.go(-1)" class="tech-support__back-icon" src="@/assets/svg/arrows/back.svg" alt="">
      <h1>{{ $t('support-requests') }}</h1>
    </div>
    <tech-support-card-id />
  </div>
</template>

<script>
import TechSupportCardId from "../../../components/template/admin/TechSupportCardId.vue";
// import {mapActions} from "vuex";

export default {
  name: "TechSupportId",
  components: {TechSupportCardId},

  data() {
    return {
      data: {}
    }
  },

  // mounted() {
  //   this.sendRequests().then((res) => {
  //     let { id } = this.$route.params
  //     this.data = res[id - 1]
  //   })
  // },
  //
  // methods: {
  //   ...mapActions(['sendRequests'])
  // }
}
</script>

<style lang="scss" scoped>
.tech-support {
  background: #F5F5F5;
  padding: 150px 50px 50px 50px;
  display: flex;
  align-items: flex-start;
  min-height: 100vh;
  gap: 50px;

  @media (max-width: 1200px) {
    gap: 100px;
    padding: 104px 21px 41px 19px;
  }

  @media (max-width: 1080px) {
    gap: 30px;
    flex-direction: column;
  }

  &__back {
    display: flex;
    align-items: center;
    gap: 10px;
    background: #F5F5F5;
    z-index: 5;

    @media (max-width: 768px) {
      position: relative;
      height: auto;
    }

    &-icon {
      width: 32px;
      height: 32px;
      cursor: pointer;
    }

    h1 {
      font-weight: 400;
      font-size: 20px;
      line-height: 22px;
      color: #343432;
      white-space: nowrap;
    }
  }
}
</style>